import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Seo from "../components/Seo";
import HeroV8 from "../components/HeroV8";
import FeaturedContent from "../components/FeaturedContent";
import OurAccreditations from "../components/OurAccreditations";

import {
  accreditationsFeaturedContent,
  accreditationsHeroDetails,
} from "../constants";

const Accreditations = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: allFile(
        filter: { name: { in: "hero-brown-books-18th-edition" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 2.99
            )
          }
        }
      }
      featuredContentImages: allFile(
        filter: {
          name: {
            in: ["01-chas-accredited-logo", "03-part-p", "06-nic-eic-logo"]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    }
  `);

  //console.log("Data - Sponsorship: ", data);

   // faqs style structured data - to do
   const structuredData = (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Intact Electrical Ltd - Accreditations",
        "url":  "https://intact-electrical.co.uk/accreditations",
        "logo": "https://intact-electrical.co.uk/static/0e28fe2974beb0df4a3655e8f2b63a68/ad11a/logo-icon.avif"
      })}
    </script>
  )

  const featuredContentImages = data.featuredContentImages.nodes;

  const heroBackgroundImage =
    data.heroImage.nodes[0].childImageSharp.gatsbyImageData;

  const {
    buttonLeft,
    buttonLeftLink,
    buttonRight,
    buttonRightLink,
    excerpt,
    titleTop,
    titleBottom,
  } = accreditationsHeroDetails;

  return (
    <>
      <Seo       
        title="Accreditations"
        description="IPAF accredited ,Part P certified, NIC EIC approved contractors and domestic installers, IET 18th Edition, CHAS accredited, SSIP"
        post={false}
        metaRobots="follow, index"
        structuredData={structuredData}
      />
      <HeroV8
        heroImage={heroBackgroundImage}
        heroTitleTop={titleTop}
        heroTitleBottom={titleBottom}
        excerpt={excerpt}
        btnLeft={buttonLeft}
        btnLeftLink={buttonLeftLink}
        btnRight={buttonRight}
        btnRightLink={buttonRightLink}
      />
      <FeaturedContent
        featuredContent={accreditationsFeaturedContent}
        featuredContentImages={featuredContentImages}
      />
      <OurAccreditations />
    </>
  );
};

export default Accreditations;
