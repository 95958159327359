import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styles from "../styles/style.js";
import Headings from "./Headings.js";

const OurAccreditations = () => {
  const data = useStaticQuery(graphql`
    query {
      smallImageCertifications: allFile(
        filter: {
          name: {
            in: [
              "ipaf-01-accredited-logo"
              "part-p-logo"
              "01-chas-accredited-logo"
              "ssip-03-logo"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.0
            )
          }
        }
      }
      largeImageCertifications: allFile(
        filter: {
          name: { in: ["nic-eic-contractor", "hero-brown-books-18th-edition"] }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.1
            )
          }
        }
      }
    }
  `);

  //console.log("data: ", data);

  const ipafaccreditation = data.smallImageCertifications.nodes.find(
    (image) => {
      return image.name === "ipaf-01-accredited-logo";
    }
  );

  const partP = data.smallImageCertifications.nodes.find((image) => {
    return image.name === "part-p-logo";
  });

  const wiringServices = data.largeImageCertifications.nodes.find((image) => {
    return image.name === "nic-eic-contractor";
  });

  const eighteenthEdition = data.largeImageCertifications.nodes.find(
    (image) => {
      return image.name === "hero-brown-books-18th-edition";
    }
  );

  const chas = data.smallImageCertifications.nodes.find((image) => {
    return image.name === "01-chas-accredited-logo";
  });

  const ssip = data.smallImageCertifications.nodes.find((image) => {
    return image.name === "ssip-03-logo";
  });

  return (
    <div className={`bg-[#ffffff] ${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <section id="our-projects-gallery">
          <Headings
            titleTop="Our accreditations"
            titleBottom="& certifications"
            description="Intact Electrical are certified and accredited with multiple professional bodies. Trust in us and be safe in the knowledge that your electrical installations are carried out to current industry standards."
          />
          <div className="lg:flex items-stretch mt-4 mb-8">
            <div className="lg:w-1/2">
              <div className="sm:flex items-center justify-between xl:gap-x-8 gap-x-6">
                <div className="sm:w-1/2 relative w-full h-full">
                  <div>
                    <div
                      className="absolute left-0 bottom-0 w-full h-full z-10 "
                      style={{
                        backgroundImage:
                          "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
                      }}
                    ></div>

                    <div className="absolute bottom-0 left-0 p-6 z-10">
                      <h2 className="text-xl text-white">IPAF accredited</h2>
                      <p className="text-base leading-4 text-quinary mt-2">
                        powered access license
                      </p>
                    </div>
                  </div>
                  <GatsbyImage
                    image={ipafaccreditation.childImageSharp.gatsbyImageData}
                    alt={ipafaccreditation.name}
                    className="w-full"
                    loading="lazy"
                  />
                </div>
                <div className="sm:w-1/2 sm:mt-0 mt-4 relative">
                  <div>
                    <div
                      className="absolute left-0 bottom-0 w-full h-full z-10"
                      style={{
                        backgroundImage:
                          "linear-gradient(180deg,transparent,rgba(0,0,0,.9))",
                      }}
                    ></div>
                    <div className="absolute bottom-0 left-0 p-6 z-10">
                      <h2 className="text-xl text-white">Part P certified</h2>
                      <p className="text-base leading-4 text-quinary mt-2">
                        electrical safety first
                      </p>
                    </div>
                  </div>
                  <GatsbyImage
                    image={partP.childImageSharp.gatsbyImageData}
                    alt={ipafaccreditation.name}
                    className="w-full"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="relative">
                <div>
                  <div
                    className="absolute left-0 bottom-0 w-full h-full z-10"
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg,transparent,rgba(0,0,0,.4))",
                    }}
                  ></div>
                  <div className="absolute bottom-0 left-0 md:p-10 p-6 z-10">
                    <h2 className="text-xl text-white">
                      NIC EIC approved contractors
                    </h2>
                    <p className="text-base leading-4 text-quinary mt-2">
                      & domestic installers
                    </p>
                  </div>
                </div>
                <GatsbyImage
                  image={wiringServices.childImageSharp.gatsbyImageData}
                  alt={wiringServices.name}
                  className="w-full mt-8 md:mt-6"
                  loading="lazy"
                  style={{ maxHeight: "540px" }}
                />
              </div>
            </div>
            <div className="lg:w-1/2 xl:ml-8 lg:ml-4 lg:mt-0 md:mt-6 mt-4 lg:flex flex-col justify-between">
              <div className="relative">
                <div>
                  <div
                    className="absolute left-0 bottom-0 w-full h-full z-10"
                    style={{
                      backgroundImage:
                        "linear-gradient(180deg,transparent,rgba(0,0,0,.9))",
                    }}
                  ></div>
                  <div className="absolute bottom-0 left-0 md:p-10 p-6 z-10">
                    <h2 className="text-xl text-white">IET 18th Edition</h2>
                    <p className="text-base leading-4 text-quinary mt-2">
                      latest wiring regulations
                    </p>
                  </div>
                </div>
                <GatsbyImage
                  image={eighteenthEdition.childImageSharp.gatsbyImageData}
                  alt={eighteenthEdition.name}
                  className="w-full"
                  loading="lazy"
                  style={{ maxHeight: "540px" }}
                />
              </div>
              <div className="sm:flex items-center justify-between xl:gap-x-8 gap-x-6 md:mt-6 mt-4">
                <div className="relative w-full">
                  <div>
                    <div
                      className="absolute left-0 bottom-0 w-full h-full z-10"
                      style={{
                        backgroundImage:
                          "linear-gradient(180deg,transparent,rgba(0,0,0,.9))",
                      }}
                    ></div>
                    <div className="absolute bottom-0 left-0 p-6 z-10">
                      <h2 className="text-xl text-white">CHAS accredited</h2>
                      <p className="text-base leading-4 text-quinary mt-2">
                        health & safety first
                      </p>
                    </div>
                  </div>
                  <GatsbyImage
                    image={chas.childImageSharp.gatsbyImageData}
                    alt={chas.name}
                    className="w-full"
                    loading="lazy"
                  />
                </div>
                <div className="relative w-full sm:mt-0 mt-4">
                  <div>
                    <div
                      className="absolute left-0 bottom-0 w-full h-full z-10"
                      style={{
                        backgroundImage:
                          "linear-gradient(180deg,transparent,rgba(0,0,0,.9))",
                      }}
                    ></div>
                    <div className="absolute bottom-0 left-0 p-6 z-10">
                      <h2 className="text-xl text-white">Safety schemes</h2>
                      <p className="text-base leading-4 text-quinary mt-2">
                        in procurement - safe contractor
                      </p>
                    </div>
                  </div>
                  <GatsbyImage
                    image={ssip.childImageSharp.gatsbyImageData}
                    alt={ssip.name}
                    className="w-full"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OurAccreditations;
